"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.courseAssignmentTypeByValue = exports.startDateLabelByValue = exports.subjectStatusLabelByValue = exports.getShipmentStatusLabelByValue = exports.getShipmentStatusDescriptionLabelByValue = void 0;
var graphql_api_admin_1 = require("@fdha/graphql-api-admin");
var utils_1 = require("../../internal/utils");
var getShipmentStatusDescriptionLabelByValue = function (systemType) {
    var _a;
    var i18nFirstSeparator = (0, utils_1.getI18nFirstSeparator)(systemType);
    var i18nPrefix = "temporary".concat(i18nFirstSeparator);
    var statusUnavailable = {
        key: "".concat(i18nPrefix, "meals.deliveryShipmentStatusDescription.statusUnavailable"),
        fallback: "We can't display this delivery status right now, please check again later.",
    };
    return _a = {},
        _a[graphql_api_admin_1.TrackingStatus.BeingPrepared] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatusDescription.beingPrepared"),
            fallback: 'Your food is being prepared.',
        },
        _a[graphql_api_admin_1.TrackingStatus.Delivered] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatusDescription.delivered"),
            fallback: 'Your delivery has arrived.',
        },
        _a[graphql_api_admin_1.TrackingStatus.Failure] = statusUnavailable,
        _a[graphql_api_admin_1.TrackingStatus.Returned] = statusUnavailable,
        _a[graphql_api_admin_1.TrackingStatus.Transit] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatusDescription.inTransit"),
            fallback: 'Your delivery is en route.',
        },
        _a[graphql_api_admin_1.TrackingStatus.Unknown] = statusUnavailable,
        _a;
};
exports.getShipmentStatusDescriptionLabelByValue = getShipmentStatusDescriptionLabelByValue;
var getShipmentStatusLabelByValue = function (systemType) {
    var _a;
    var i18nFirstSeparator = (0, utils_1.getI18nFirstSeparator)(systemType);
    var i18nPrefix = "temporary".concat(i18nFirstSeparator);
    return _a = {},
        _a[graphql_api_admin_1.TrackingStatus.BeingPrepared] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatus.beingPrepared"),
            fallback: 'Your food is being prepared',
        },
        _a[graphql_api_admin_1.TrackingStatus.Delivered] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatus.delivered"),
            fallback: 'Your delivery has arrived',
        },
        _a[graphql_api_admin_1.TrackingStatus.Failure] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatus.statusUnavailable"),
            fallback: 'Delivery status unavailable',
        },
        _a[graphql_api_admin_1.TrackingStatus.Returned] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatus.statusUnavailable"),
            fallback: 'Delivery status unavailable',
        },
        _a[graphql_api_admin_1.TrackingStatus.Transit] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatus.inTransit"),
            fallback: 'Your delivery is en route',
        },
        _a[graphql_api_admin_1.TrackingStatus.Unknown] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatus.unknown"),
            fallback: 'Tracking ID unavailable',
        },
        _a;
};
exports.getShipmentStatusLabelByValue = getShipmentStatusLabelByValue;
exports.subjectStatusLabelByValue = (_a = {},
    _a[graphql_api_admin_1.SubjectStatus.Discontinued] = 'Discontinued',
    _a[graphql_api_admin_1.SubjectStatus.Ongoing] = 'Ongoing',
    _a[graphql_api_admin_1.SubjectStatus.Screening] = 'Screening',
    _a[graphql_api_admin_1.SubjectStatus.StudyCompleted] = 'Study completed',
    _a[graphql_api_admin_1.SubjectStatus.ScreenFailed] = 'Screen failed',
    _a[graphql_api_admin_1.SubjectStatus.CoachApproved] = 'Coach approved',
    _a[graphql_api_admin_1.SubjectStatus.Draft] = 'Draft mode',
    _a);
exports.startDateLabelByValue = {
    PIK: 'C1D1 date',
    LARC: 'Radiotherapy start date',
    N001: null,
    SER1: null,
};
exports.courseAssignmentTypeByValue = (_b = {},
    _b[graphql_api_admin_1.CourseAssignmentTypes.Trial] = 'Trial-level',
    _b[graphql_api_admin_1.CourseAssignmentTypes.None] = 'None',
    _b[graphql_api_admin_1.CourseAssignmentTypes.Site] = 'Site-level',
    _b);
