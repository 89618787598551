import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Button, TextField, useSnackbar } from '@fdha/web-ui-library';
import {
  GroupInput,
  GroupListing,
  ListGroupsDocument,
  useAddGroupMutation,
  useDeleteGroupsMutation,
  useUpdateGroupMutation,
} from '@fdha/graphql-api-admin';

interface GroupSchema {
  name: string;
}

const groupValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required(),
});

interface AddOrEditGroupProps {
  data?: GroupListing;
  onDelete?: () => void;
  onFinish: () => void;
}

const AddOrEditGroup: React.FC<AddOrEditGroupProps> = ({
  data,
  onFinish,
  onDelete: onDeleteCallback,
}) => {
  const [addGroup] = useAddGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();
  const [deleteGroup] = useDeleteGroupsMutation();

  const { showSnackbar } = useSnackbar();

  const initialValues: GroupSchema = {
    name: data?.name || '',
  };

  const addOrEditGroup = (payload: GroupInput) => {
    if (data) {
      return updateGroup({
        variables: { id: data.id, props: payload },
        update(cache, { data: result }) {
          cache.modify({
            id: cache.identify(data),
            fields: {
              name(existingValue) {
                return result?.updateGroup?.name ?? existingValue;
              },
            },
          });
        },
      });
    }
    return addGroup({
      variables: { newGroup: payload },
      refetchQueries: [ListGroupsDocument],
    });
  };

  const onDelete = async () => {
    if (!data) {
      return;
    }

    try {
      await deleteGroup({
        variables: { ids: data.id },
        refetchQueries: [ListGroupsDocument],
      });
      showSnackbar({
        message: 'Group deleted with success',
        severity: 'success',
      });
      onDeleteCallback && onDeleteCallback();
    } catch {
      showSnackbar({
        message: 'Error to delete group',
        severity: 'error',
      });
    } finally {
      onFinish();
    }
  };

  const onCancel = () => {
    showSnackbar({
      message: 'Changes not saved',
      severity: 'warning',
    });
    onFinish();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={groupValidationSchema}
      validateOnMount
      onSubmit={async (values) => {
        try {
          await addOrEditGroup(values);
          showSnackbar({
            message: data
              ? 'Group updated with success'
              : 'Group added with success',
            severity: 'success',
          });
        } catch {
          showSnackbar({
            message: data ? 'Error to update group' : 'Error to add group',
            severity: 'error',
          });
        } finally {
          onFinish();
        }
      }}
    >
      {({ isSubmitting, isValid, handleChange, values }) => {
        return (
          <Form>
            <TextField
              title="Group name"
              name="name"
              placeholder="Type here..."
              type="text"
              autoFocus
              onChange={handleChange}
              value={values.name}
            />
            <Box display="flex" justifyContent="space-between" marginTop={3}>
              <Box>
                {data && (
                  <Button
                    color="error"
                    onClick={onDelete}
                    size="large"
                    variant="outlined"
                    data-testid="DELETE_DIALOG_BUTTON"
                  >
                    DELETE
                  </Button>
                )}
              </Box>
              <Box>
                <Button
                  color="primary"
                  onClick={onCancel}
                  size="large"
                  data-testid="CANCEL_DIALOG_BUTTON"
                >
                  CANCEL
                </Button>
                <Button
                  disabled={!isValid || isSubmitting}
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="submit"
                  data-testid="SAVE_DIALOG_BUTTON"
                >
                  SAVE
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddOrEditGroup;
