"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertSnakeCaseObjectToCamelCaseObject = exports.convertPhoneToE164US = exports.convertPhoneToObfuscatePhone = exports.convertEmailToObfuscateEmail = exports.convertQuestionSplittedPropsToQuestionSpreadProps = exports.convertQuestionSpreadPropsToQuestionGroupedProps = exports.convertQuestionSpreadPropsToQuestionSplittedProps = exports.convertQuestionGroupedPropsToQuestionSplittedProps = exports.convertQuestionGroupedPropsToQuestionSpreadProps = void 0;
var graphql_api_admin_1 = require("@fdha/graphql-api-admin");
var lodash_1 = require("lodash");
var getQuestionCommonProps = function (input) {
    return {
        id: input.id,
        title: input.title,
        helpText: input.helpText,
        type: input.type,
    };
};
// From grouped props
/**
 * Convert question grouped props to question spread props
 * @param input Question with grouped props format (all inside customProps)
 * @returns Question with spread props format (old format of question props)
 */
var convertQuestionGroupedPropsToQuestionSpreadProps = function (input) {
    var questionType = input.type;
    var question = getQuestionCommonProps(input);
    if (input.customProps) {
        var customProps = (0, lodash_1.omit)(input.customProps, '__typename');
        // Custom props
        switch (questionType) {
            case graphql_api_admin_1.FormBuilderQuestionType.MultipleChoice:
            case graphql_api_admin_1.FormBuilderQuestionType.SingleChoice:
            case graphql_api_admin_1.FormBuilderQuestionType.Binary:
            case graphql_api_admin_1.FormBuilderQuestionType.Scale: {
                var possibleAnswers = input.customProps.possibleAnswers;
                question.possibleAnswers = possibleAnswers;
                break;
            }
            case graphql_api_admin_1.FormBuilderQuestionType.OpenText: {
                var _a = input.customProps, placeholderText = _a.placeholderText, maxLength = _a.maxLength;
                question.maxLength = maxLength;
                question.placeholderText = placeholderText;
                break;
            }
            case graphql_api_admin_1.FormBuilderQuestionType.Number: {
                var placeholderText = input.customProps.placeholderText;
                question.placeholderText = placeholderText;
                break;
            }
            case graphql_api_admin_1.FormBuilderQuestionType.Date: {
                question.customProps = customProps;
                break;
            }
        }
    }
    return question;
};
exports.convertQuestionGroupedPropsToQuestionSpreadProps = convertQuestionGroupedPropsToQuestionSpreadProps;
/**
 * Convert question grouped props to question splitted props
 * @param input Question with grouped props format (all inside customProps)
 * @returns Question with splitted props format (specific props for each question type)
 */
var convertQuestionGroupedPropsToQuestionSplittedProps = function (input) {
    var question = getQuestionCommonProps(input);
    // Adding type specific props
    if (input.customProps) {
        var customProps = __assign({}, input.customProps);
        delete customProps.__typename;
        if ('possibleAnswers' in customProps) {
            switch (input.type) {
                case graphql_api_admin_1.FormBuilderQuestionType.MultipleChoice:
                    question.multipleChoiceProps = customProps;
                    break;
                case graphql_api_admin_1.FormBuilderQuestionType.SingleChoice:
                    question.singleChoiceProps = customProps;
                    break;
                case graphql_api_admin_1.FormBuilderQuestionType.Binary:
                    question.binaryProps = customProps;
                    break;
                case graphql_api_admin_1.FormBuilderQuestionType.Scale:
                    question.scaleProps = customProps;
                    break;
            }
        }
        else if ('maxLength' in customProps) {
            question.openTextProps = customProps;
        }
        else if ('placeholderText' in customProps) {
            question.numberProps = customProps;
        }
        if (input.type === graphql_api_admin_1.FormBuilderQuestionType.Date) {
            question.dateProps = customProps;
        }
    }
    return question;
};
exports.convertQuestionGroupedPropsToQuestionSplittedProps = convertQuestionGroupedPropsToQuestionSplittedProps;
// From spread props
/**
 * Convert question spread props to question splitted props
 * @param input Question with spread props format (old format of question props)
 * @returns Question with splitted props format (specific props for each question type)
 */
var convertQuestionSpreadPropsToQuestionSplittedProps = function (input) {
    var question = getQuestionCommonProps(input);
    // Adding type specific props
    switch (input.type) {
        case graphql_api_admin_1.FormBuilderQuestionType.MultipleChoice: {
            var possibleAnswers = input.possibleAnswers;
            if (!possibleAnswers) {
                throw new Error('Possible answers are required for multiple choice and single choice questions');
            }
            question.multipleChoiceProps = { possibleAnswers: possibleAnswers };
            break;
        }
        case graphql_api_admin_1.FormBuilderQuestionType.SingleChoice: {
            var possibleAnswers = input.possibleAnswers;
            if (!possibleAnswers) {
                throw new Error('Possible answers are required for multiple choice and single choice questions');
            }
            question.singleChoiceProps = { possibleAnswers: possibleAnswers };
            break;
        }
        case graphql_api_admin_1.FormBuilderQuestionType.Binary: {
            var possibleAnswers = input.possibleAnswers;
            if (!possibleAnswers) {
                throw new Error('Possible answers are required for binary questions');
            }
            question.binaryProps = { possibleAnswers: possibleAnswers };
            break;
        }
        case graphql_api_admin_1.FormBuilderQuestionType.Scale: {
            var possibleAnswers = input.possibleAnswers;
            if (!possibleAnswers) {
                throw new Error('Possible answers are required for scale questions');
            }
            question.scaleProps = { possibleAnswers: possibleAnswers };
            break;
        }
        case graphql_api_admin_1.FormBuilderQuestionType.OpenText: {
            var maxLength = input.maxLength, placeholderText = input.placeholderText;
            if (!maxLength || !placeholderText) {
                throw new Error('Max length and placeholder text are required for open text questions');
            }
            question.openTextProps = { maxLength: maxLength, placeholderText: placeholderText };
            break;
        }
        case graphql_api_admin_1.FormBuilderQuestionType.Number: {
            var placeholderText = input.placeholderText;
            if (!placeholderText) {
                throw new Error('Placeholder text is required for number questions');
            }
            question.numberProps = { placeholderText: placeholderText };
            break;
        }
        case graphql_api_admin_1.FormBuilderQuestionType.Date: {
            var customProps = input.customProps;
            if (!customProps) {
                throw new Error('CustomProps required for date questions');
            }
            question.dateProps = customProps;
        }
    }
    return question;
};
exports.convertQuestionSpreadPropsToQuestionSplittedProps = convertQuestionSpreadPropsToQuestionSplittedProps;
/**
 * Convert question spread props to question grouped props
 * @param input Question with spread props format (old format of question props)
 * @returns Question with grouped props format (all inside customProps)
 */
var convertQuestionSpreadPropsToQuestionGroupedProps = function (input) {
    var question = getQuestionCommonProps(input);
    // Adding type specific props
    // For multiple choice, single choice, binary and scale questions
    if (input.possibleAnswers) {
        question.customProps = { possibleAnswers: input.possibleAnswers };
    }
    // For number question
    if (input.placeholderText) {
        question.customProps = {
            placeholderText: input.placeholderText,
        };
    }
    // For open text question
    if (input.maxLength && input.placeholderText) {
        question.customProps = {
            maxLength: input.maxLength,
            placeholderText: input.placeholderText,
        };
    }
    // For date question
    if (input.customProps) {
        question.customProps = input.customProps;
    }
    return question;
};
exports.convertQuestionSpreadPropsToQuestionGroupedProps = convertQuestionSpreadPropsToQuestionGroupedProps;
// From splitted props
/**
 * Convert question splitted props to question spread props
 * @param input Question with splitted props format (specific props for each question type)
 * @returns Question with spread props format (old format of question props)
 */
var convertQuestionSplittedPropsToQuestionSpreadProps = function (input) {
    var _a, _b, _c, _d, _e, _f, _g;
    var question = getQuestionCommonProps(input);
    // Adding type specific props
    switch (input.type) {
        case graphql_api_admin_1.FormBuilderQuestionType.MultipleChoice:
            question.possibleAnswers = (_a = input.multipleChoiceProps) === null || _a === void 0 ? void 0 : _a.possibleAnswers;
            break;
        case graphql_api_admin_1.FormBuilderQuestionType.SingleChoice:
            question.possibleAnswers = (_b = input.singleChoiceProps) === null || _b === void 0 ? void 0 : _b.possibleAnswers;
            break;
        case graphql_api_admin_1.FormBuilderQuestionType.Binary:
            question.possibleAnswers = (_c = input.binaryProps) === null || _c === void 0 ? void 0 : _c.possibleAnswers;
            break;
        case graphql_api_admin_1.FormBuilderQuestionType.Scale:
            question.possibleAnswers = (_d = input.scaleProps) === null || _d === void 0 ? void 0 : _d.possibleAnswers;
            break;
        case graphql_api_admin_1.FormBuilderQuestionType.OpenText:
            question.maxLength = (_e = input.openTextProps) === null || _e === void 0 ? void 0 : _e.maxLength;
            question.placeholderText = (_f = input.openTextProps) === null || _f === void 0 ? void 0 : _f.placeholderText;
            break;
        case graphql_api_admin_1.FormBuilderQuestionType.Number:
            question.placeholderText = (_g = input.numberProps) === null || _g === void 0 ? void 0 : _g.placeholderText;
            break;
        case graphql_api_admin_1.FormBuilderQuestionType.Date:
            question.customProps = input.dateProps;
            break;
    }
    return question;
};
exports.convertQuestionSplittedPropsToQuestionSpreadProps = convertQuestionSplittedPropsToQuestionSpreadProps;
/**
 * Converts email to obfuscated email
 * @param email - Email to convert
 * @returns email with obfuscated username and domain
 */
var convertEmailToObfuscateEmail = function (email) {
    var _a = email.split('@'), username = _a[0], domain = _a[1];
    var firstCharacters = username.length <= 8 ? Math.round(username.length / 2) : 4;
    var obfuscatedUsername = username.slice(0, firstCharacters) + '*****';
    var obfuscatedDomain = domain.slice(0, 1) + '******';
    return "".concat(obfuscatedUsername, "@").concat(obfuscatedDomain);
};
exports.convertEmailToObfuscateEmail = convertEmailToObfuscateEmail;
/**
 * Converts phone to obfuscated phone
 * @param phone - Phone to convert
 * @returns obfuscated phone
 */
var convertPhoneToObfuscatePhone = function (phone) {
    var lastFourDigits = phone.slice(-4);
    var obfuscatedPhone = '******-' + lastFourDigits;
    return obfuscatedPhone;
};
exports.convertPhoneToObfuscatePhone = convertPhoneToObfuscatePhone;
/**
 * Converts phone to E164 US format
 * @param phone - Phone to convert
 * @returns phone in E164 US format
 */
var convertPhoneToE164US = function (phone) {
    var e164USRegex = /^\+1\d{10}$/;
    if (e164USRegex.test(phone)) {
        return phone;
    }
    var phoneWithoutDashes = phone.replace(/-/g, '');
    return "+1".concat(phoneWithoutDashes);
};
exports.convertPhoneToE164US = convertPhoneToE164US;
var convertSnakeCaseObjectToCamelCaseObject = function (obj) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (0, lodash_1.mapKeys)(obj, function (_, key) {
        return (0, lodash_1.camelCase)(key);
    });
};
exports.convertSnakeCaseObjectToCamelCaseObject = convertSnakeCaseObjectToCamelCaseObject;
