import React, { Dispatch, SetStateAction, useState } from 'react';
import { styled } from '@mui/material';
import { Accordion } from '@fdha/web-ui-library';
import { PatientUser } from '@fdha/graphql-api-admin';

import Chip from '../../../../../components/Chip/Chip';

import ViewModeOnboardingInformation from './ViewModeOnboardingInformation';
import EditModeOnboardingInformation from './EditModeOnboardingInformation';

export const Interest = styled(Chip)(() => ({
  marginRight: 8,
  marginBottom: 8,
  borderRadius: 15,
}));

export interface OnboardingInformationProps {
  patientUser?: PatientUser | null;
  isCsr?: boolean;
  handleEditMode: Dispatch<SetStateAction<boolean>>;
}

const OnboardingInformation = (props: {
  patientUser?: PatientUser | null;
  isCsr?: boolean;
}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <Accordion
      title="Account information"
      data-testid="ONBOARDING_INFO"
      sx={{ px: 1 }}
    >
      {editMode ? (
        <EditModeOnboardingInformation
          patientUser={props.patientUser}
          handleEditMode={setEditMode}
        />
      ) : (
        <ViewModeOnboardingInformation
          patientUser={props.patientUser}
          isCsr={props.isCsr}
          handleEditMode={setEditMode}
        />
      )}
    </Accordion>
  );
};

export default OnboardingInformation;
