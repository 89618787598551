import { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Loader } from '@fdha/web-ui-library';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import config from '@fdha/aws-config-admin';
import { useGetSiteStaffUserQuery, UserType } from '@fdha/graphql-api-admin';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import {
  BasePage,
  ManagePassword,
  ProfileCard,
  SiteStaffSpecificInfo,
} from '../../components';
import { siteStaffRoleLabelByValue } from '../../utils';

import ProfileInformation from './profileInformation/ProfileInformation';

interface StateProps {
  backRoute?: string;
}

const SiteStaff = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state as StateProps;
  const siteStaffId = params.siteStaffId || '';

  const { data, loading } = useGetSiteStaffUserQuery({
    variables: { id: siteStaffId },
    fetchPolicy: 'cache-and-network',
  });

  const backRouteRef = useRef(
    state?.backRoute ? state.backRoute : '/trials/site-staff-users'
  );

  const user = data?.siteStaffUser;
  const poolData = {
    UserPoolId: config.cognito.siteStaffUserPoolId,
    ClientId: config.cognito.siteStaffUserPoolClientId,
  };
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: user?.email || '',
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  return (
    <BasePage>
      <BasePage.BackButton
        handleClick={() => navigate(backRouteRef.current, { replace: true })}
      />
      {loading ? (
        <Loader />
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          flex="1 0 auto"
          flexWrap="nowrap"
        >
          <Box flex={1}>
            {user && (
              <ProfileCard name={user.name} email={user.email}>
                <SiteStaffSpecificInfo
                  associatedSite={user.site.name}
                  isActive={user.is_active}
                  joinedOn={user.created_at}
                  role={siteStaffRoleLabelByValue[user.role]}
                />
              </ProfileCard>
            )}
            <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>
              Manage account
            </Typography>
            <ManagePassword
              email={user?.email || ''}
              registrationStatus={user?.registrationStatus || ''}
              cognitoUser={cognitoUser}
              type={UserType.SiteStaff}
            />
          </Box>
          <Box flex={2} marginLeft={3}>
            <ProfileInformation siteStaffUser={user} />
          </Box>
        </Box>
      )}
    </BasePage>
  );
};

export default SiteStaff;
