import React, { useEffect, useRef, useState } from 'react';
import {
  signIn,
  getCurrentUser,
  confirmSignIn,
  rememberDevice,
} from '@aws-amplify/auth';
import { useReactiveVar } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {
  Box,
  Checkbox,
  Link,
  styled,
  useTheme,
  FormControlLabel,
} from '@mui/material';
import { Button, TextField, Typography, useDialog } from '@fdha/web-ui-library';
import { SUPPORT_EMAIL } from '@fdha/common-utils';

import {
  cognitoUser,
  setCognitoUser,
  setCognitoUserInitialValue,
} from '../states/userCognitoState';

const SMSDetails = styled(Typography)(() => ({
  marginTop: 5,
  marginBottom: 5,
}));

const initialValues = {
  code: '',
};

export interface SMSChallengeCodeProps {
  v2?: boolean;
}

interface StateProps {
  username: string;
  password: string;
}

export const SMSChallengeCode: React.FC<SMSChallengeCodeProps> = ({ v2 }) => {
  const userCognito = useReactiveVar(cognitoUser);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as StateProps;
  const userSignInData = {
    username: state.username,
    password: state.password,
  };
  const theme = useTheme();
  const dialog = useDialog();
  const isMounted = useRef(false);

  const [error, setError] = useState('');
  const [isLinkDisabled, setIsLinkDisabled] = useState<boolean>(false);
  const [shouldRememberDevice, setShouldRememberDevice] = useState(false);

  const phone = userCognito?.challengeParam?.CODE_DELIVERY_DESTINATION;

  if (!userCognito) {
    navigate('/', { replace: true });
  }

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  /************ V2 updates *********************/

  const smsTextVariant = v2 ? 'body2' : 'body1';
  const captionColor = v2
    ? theme.palette.text.secondary
    : theme.palette.text.primary;
  const detailsMargin = v2 ? 2 : 0;
  const checkboxMargin = v2 ? 0 : 3;
  const buttonColor = v2 ? 'primary' : 'secondary';

  /***********************************/

  const resendConfirmationCode = async () => {
    if (isLinkDisabled) {
      return;
    }

    setError('');

    try {
      setIsLinkDisabled(true);

      setTimeout(() => {
        if (isMounted.current) {
          setIsLinkDisabled(false);
        }
      }, 15000);

      await signIn({
        username: userSignInData?.username || '',
        password: userSignInData?.password,
      });

      const currentUser = await getCurrentUser();

      setCognitoUser({
        ...currentUser,
        challengeParam: {
          CODE_DELIVERY_DESTINATION: '',
        },
      });

      dialog.openDialog({
        title: 'We just sent you another code.',
        i18nKeyTitle: 'login:changePassword.justSentAnother',
        content: null,
        confirmButtonLabel: 'OK',
        cancelButtonLabel: '',
        handleConfirm: async () => {
          dialog.closeDialog();
        },
      });
    } catch (error) {
      setError('An error occurred while sending your code, please try again.');
    }
  };

  const handleSubmit = async (values: { code: string }) => {
    setError('');

    try {
      await confirmSignIn({ challengeResponse: values.code.toString() });
      if (shouldRememberDevice) {
        await rememberDevice();
      }
      setCognitoUserInitialValue();
      navigate('/', { replace: true });
    } catch (error: any) {
      setError(
        error.code === 'CodeMismatchException'
          ? 'Invalid verification code, please try again.'
          : 'An error occurred while verifying your code, please try again.'
      );
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent, code: string) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit({ code });
    }
  };

  const getLinkStyle = () =>
    isLinkDisabled
      ? {
          textDecoration: 'none',
          color: theme.palette.text.primary,
        }
      : {};

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange, isSubmitting }) => (
        <Form style={{ width: '100%' }}>
          <Box display="flex" flexDirection="column">
            <TextField
              title="Confirm your code"
              name="code"
              placeholder="Type code here..."
              value={values.code}
              onChange={handleChange}
              helperText={error}
              error={!!error}
              onKeyPress={(event: React.KeyboardEvent) =>
                handleKeyPress(event, values.code)
              }
              v2={v2}
            />
          </Box>
          <SMSDetails variant={smsTextVariant} sx={{ color: captionColor }}>
            A text message has just been sent to {phone}.
          </SMSDetails>
          <Link
            component="button"
            onClick={resendConfirmationCode}
            disabled={isLinkDisabled}
            style={getLinkStyle()}
          >
            <SMSDetails
              variant={smsTextVariant}
              sx={{ marginY: detailsMargin }}
            >
              {isLinkDisabled
                ? 'Wait 15 seconds before requesting again...'
                : `Didn't get it? Send another code.`}
            </SMSDetails>
          </Link>
          <SMSDetails variant={smsTextVariant} sx={{ color: captionColor }}>
            If you still didn't get the code, please contact: {SUPPORT_EMAIL}
          </SMSDetails>
          <FormControlLabel
            sx={{ marginTop: checkboxMargin }}
            label="Don't ask again on this device"
            control={
              <Checkbox
                checked={shouldRememberDevice}
                onChange={(e) => setShouldRememberDevice(e.target.checked)}
              />
            }
          />
          <Button
            type="submit"
            variant="contained"
            color={buttonColor}
            fullWidth
            sx={{ height: '42px', marginTop: 1 }}
            disabled={!values.code || isSubmitting}
          >
            Confirm
          </Button>
        </Form>
      )}
    </Formik>
  );
};
