import { CourseAssignmentTypes } from '@fdha/graphql-api-admin';
import { Warning, Option } from '@fdha/web-ui-library';
import { Stack, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';

interface CourseAssignmentWarningProps {
  courseAssignmentType: CourseAssignmentTypes;
  trialCourses: Option[];
}

const CourseAssignmentWarning: FC<CourseAssignmentWarningProps> = ({
  courseAssignmentType,
  trialCourses,
}) => {
  const theme = useTheme();

  if (!trialCourses.length) {
    return null;
  }

  return (
    <Warning
      iconSize="medium"
      text={
        <Stack spacing={1}>
          <Stack>
            <Typography variant="body2">
              Overriding trial-level course
            </Typography>
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {courseAssignmentType === CourseAssignmentTypes.Site
                ? 'The following trial-level courses will be overwritten'
                : 'No course will be assigned to any subjects under this trial'}
            </Typography>
          </Stack>
          {courseAssignmentType === CourseAssignmentTypes.Site && (
            <Typography variant="caption" color={theme.palette.text.secondary}>
              <b>{trialCourses.map((course) => course.label).join(', ')}</b>
            </Typography>
          )}
        </Stack>
      }
    />
  );
};

export default CourseAssignmentWarning;
