"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubjectIdPrefix = exports.isMissingOnboardingCall = exports.ACTIVE_SUBJECT_STATUS = void 0;
var graphql_api_admin_1 = require("@fdha/graphql-api-admin");
exports.ACTIVE_SUBJECT_STATUS = [
    graphql_api_admin_1.SubjectStatus.Ongoing,
    graphql_api_admin_1.SubjectStatus.Screening,
    graphql_api_admin_1.SubjectStatus.CoachApproved,
];
/**
 * Returns true if the subject is missing an onboarding call.
 * @param status status of the subject
 * @param onboardingCallNeeded if the onboarding call is needed
 * @param onboardingCallScheduled if the onboarding call is scheduled
 * @returns true if onboarding call is needed and not scheduled
 */
var isMissingOnboardingCall = function (status, onboardingCallNeeded, onboardingCallScheduled) {
    return (!!status &&
        exports.ACTIVE_SUBJECT_STATUS.includes(status) &&
        onboardingCallNeeded === true &&
        onboardingCallScheduled === false);
};
exports.isMissingOnboardingCall = isMissingOnboardingCall;
/**
 * Generates a subject ID prefix based on the given template and dynamic values.
 * Replaces placeholders in the template with corresponding values from the provided object.
 * The `$subjectNumber` placeholder is always replaced with an empty string.
 * @param values - An object containing key-value pairs where keys represent placeholders (e.g., "$trialAbbreviation").
 * @param template - Template string containing placeholders (e.g., "$trialAbbreviation-$siteId-$subjectNumber").
 * @returns The generated prefix string with placeholders replaced.
 */
var getSubjectIdPrefix = function (values, template) {
    if (!template) {
        return '';
    }
    var templateWithoutSubjectNumber = template.replace(/\$subjectNumber/g, '');
    return Object.entries(values).reduce(function (result, _a) {
        var token = _a[0], value = _a[1];
        return result.replace(token, value || '');
    }, templateWithoutSubjectNumber);
};
exports.getSubjectIdPrefix = getSubjectIdPrefix;
