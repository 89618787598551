import {
  Link,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Tab, Tabs, useTheme, Typography, Stack } from '@mui/material';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import {
  Loader,
  useSnackbar,
  useDialog,
  SelectType,
} from '@fdha/web-ui-library';
import Icon from 'react-eva-icons';
import config from '@fdha/aws-config-admin';
import { useFeatureFlag, useSystemSetting } from '@fdha/common-hooks';
import {
  useGetFlaggedNoteQuery,
  useGetPatientUserQuery,
  useGetProfileQuery,
  useDeactivateAccountMutation,
  GetPatientUserDocument,
  useUpdatePatientUserMutation,
  UserType,
  MainGoalType,
} from '@fdha/graphql-api-admin';

import { useAddRemoveGroups, useGetUserType } from '../../hooks';
import {
  BasePage,
  ManageAccountButton,
  ManageCoachInfo,
  ProfileCard,
  PatientSpecificInfo,
  SelectMainGoal,
  SelectMultipleGroups,
  ManagePassword,
} from '../../components';

import NoteCardPreview from './tabs/Notes/NoteCardPreview';

const getTabs = (showBhbDataSurvey: boolean, isCsr?: boolean) => [
  { label: 'About', href: 'about' },
  ...(!isCsr ? [{ label: 'Notes', href: 'notes' }] : []),
  { label: 'Surveys', href: 'surveys' },
  ...(showBhbDataSurvey ? [{ label: 'Data', href: 'data' }] : []),
  { label: 'Learning', href: 'learning' },
  { label: 'Documents', href: 'documents' },
];

interface StateProps {
  backRoute: string;
}

const Patient = () => {
  const theme = useTheme();
  const location = useLocation();
  const params = useParams();
  const match = useMatch('/patient/:patientId/:tabId/*');
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();
  const { isFeatureEnabled, isLoading: featureEnabledLoading } =
    useFeatureFlag();
  const { addRemoveGroups, removeGroups } = useAddRemoveGroups();
  const { isCsr } = useGetUserType();

  const showBhbDataSurvey = isFeatureEnabled('show_bhb_data_survey');
  const showSiteFeatures = isFeatureEnabled('show_site_features');

  const { value: isOtpEnabled, isLoading: loadingIsOtpEnabled } =
    useSystemSetting('isOtpEnabled');

  const state = location.state as StateProps;
  const patientId = params.patientId || '';

  const [backRoute] = useState(state?.backRoute ?? '/');
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

  const [updatePatientUser] = useUpdatePatientUserMutation();
  const [deactivateAccount] = useDeactivateAccountMutation({
    variables: { id: patientId },
    refetchQueries: [GetPatientUserDocument],
  });

  const { data: patientData, loading } = useGetPatientUserQuery({
    variables: {
      id: patientId,
    },
  });

  const { data: myData } = useGetProfileQuery();

  const { data: flaggedNoteData } = useGetFlaggedNoteQuery({
    variables: { patientId },
  });

  const flaggedNote = flaggedNoteData?.flaggedNote;
  const patientUser = patientData?.patientUser;

  const poolData = {
    UserPoolId: config.cognito.patientUserPoolId,
    ClientId: config.cognito.patientUserPoolClientId,
  };
  const userPool = new CognitoUserPool(poolData);

  const userData = {
    Username: patientUser?.email || '',
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  const isCT = patientUser?.type === UserType.ClinicalTrialPatient;

  const tabs = useMemo(
    () => getTabs(showBhbDataSurvey, isCsr),
    [showBhbDataSurvey, isCsr]
  );
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const tabId = match?.params.tabId || 'about';
    setTab(tabs.findIndex((tab) => tab.href === tabId));
  }, [match, tabs]);

  useEffect(() => {
    setSelectedGroups(patientUser?.groups || []);
  }, [patientUser?.groups]);

  const handleUpdateUserType = async (userId: string, newType: UserType) => {
    await updatePatientUser({
      variables: { id: userId, props: { type: newType } },
      refetchQueries: [GetPatientUserDocument],
    });
  };

  const handleUpdateUserGoal = async (
    userId: string,
    newGoal: MainGoalType
  ) => {
    await updatePatientUser({
      variables: { id: userId, props: { main_goal: newGoal } },
      refetchQueries: [GetPatientUserDocument],
    });
  };

  const handleDeactivateAccClick = () => {
    openDialog({
      title: 'Are you sure you want to deactivate this account?',
      content: `${patientUser?.name}’s account will no longer exist. This action can’t be undone.`,
      confirmButtonLabel: 'Deactivate',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => {
        try {
          await deactivateAccount();

          showSnackbar({
            message: 'User deactivated with success',
            severity: 'success',
          });

          navigate(backRoute, { replace: true });
        } catch (e) {
          showSnackbar({
            message: 'Failed to deactivate account',
            severity: 'error',
          });
        } finally {
          closeDialog();
        }
      },
    });
  };

  const canDeactivateAccount = () => {
    return myData?.me.is_admin;
  };

  const isLoading = loading || featureEnabledLoading || loadingIsOtpEnabled;

  return (
    <BasePage data-testid="PATIENT_CONTAINER">
      <BasePage.BackButton
        handleClick={() => navigate(backRoute, { replace: true })}
      />
      {isLoading ? (
        <Loader />
      ) : patientUser ? (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          flex="1 0 auto"
          flexWrap="nowrap"
        >
          <Box flex={1} data-testid="PATIENT_LEFT_CONTAINER">
            <ProfileCard
              name={patientUser.name}
              email={patientUser.email}
              phoneNumber={patientUser.phone_number}
              subtitle={isCT ? patientUser.subject_id || undefined : undefined}
            >
              <PatientSpecificInfo
                birthDate={patientUser.birthdate}
                isCT={isCT}
                height={patientUser.height}
                weight={patientUser.weight}
                joinedOn={patientUser.created_at}
                timezone={patientUser.timezone || undefined}
                restrictAccess={isCsr}
                showSiteFeatures={showSiteFeatures}
                preferredLanguage={patientUser.language ?? undefined}
              />
            </ProfileCard>
            <SelectType
              sx={{ mt: 2 }}
              userId={patientUser.id}
              userType={patientUser.type}
              handleUpdateUserType={handleUpdateUserType}
              fullWidth
              disabled={isCsr}
            />
            <Box my={2}>
              <SelectMultipleGroups
                initialSelectedGroups={patientUser.groups || []}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                onCloseSelector={(groupIdsToAdd, groupIdsToRemove) => {
                  if (patientId) {
                    addRemoveGroups(
                      patientId,
                      groupIdsToAdd,
                      groupIdsToRemove,
                      [GetPatientUserDocument]
                    );
                  }
                }}
                onRemoveChips={(groupIdsToRemove) => {
                  if (patientId) {
                    removeGroups(patientId, groupIdsToRemove, [
                      GetPatientUserDocument,
                    ]);
                  }
                }}
                showPlaceholderOnly
                showChips={!isCsr}
                fullWidth
                disabled={isCsr}
              />
            </Box>
            {!isCT && (
              <SelectMainGoal
                userId={patientUser.id}
                mainGoal={patientUser.main_goal ?? undefined}
                handleUpdateGoal={handleUpdateUserGoal}
                fullWidth
                disabled={isCsr}
              />
            )}
            <ManageCoachInfo
              mainCoach={patientUser.coach}
              backupCoach={patientUser.backup_coach}
              patientId={patientUser.id}
              patientName={patientUser.name}
            />
            {flaggedNote ? (
              <Box marginTop={3}>
                <NoteCardPreview
                  disableEdit
                  note={flaggedNote}
                  patientId={patientId}
                />
              </Box>
            ) : null}
            <Stack spacing={2} mt={3}>
              <Typography variant="h6">Manage account</Typography>
              {patientUser.trial?.id && (
                <ManageAccountButton
                  onClick={() => navigate('language')}
                  data-testid="PATIENT_LANGUAGE_BUTTON"
                >
                  <Typography
                    variant="h6"
                    color={theme.palette.secondary.contrastText}
                  >
                    Language
                  </Typography>
                </ManageAccountButton>
              )}
              {!isOtpEnabled && (
                <ManagePassword
                  email={patientUser.email}
                  registrationStatus={patientUser.registrationStatus || ''}
                  cognitoUser={cognitoUser}
                  type={UserType.Patient}
                />
              )}
              {canDeactivateAccount() && (
                <ManageAccountButton
                  onClick={handleDeactivateAccClick}
                  data-testid="DEACTIVATE_ACCOUNT_BUTTON"
                  color="error"
                >
                  <Box display="flex" alignItems="center">
                    <Icon
                      name="alert-circle-outline"
                      fill={theme.palette.error.textDark}
                      size="large"
                    />
                    <Typography variant="h6" sx={{ ml: 1 }}>
                      Deactivate account
                    </Typography>
                  </Box>
                </ManageAccountButton>
              )}
            </Stack>
          </Box>
          <Box
            flex={2}
            marginLeft="52px"
            alignItems="stretch"
            display="flex"
            marginBottom={10}
            minWidth="0"
          >
            <Box display="flex" flex={1} flexDirection="column" width="100%">
              <Box
                borderBottom={1}
                borderColor="divider"
                marginBottom="40px"
                flex="0 1 auto"
              >
                <Tabs
                  value={tab}
                  onChange={(_, newTab) => setTab(newTab)}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {tabs.map((tab) => (
                    <Tab
                      data-testid={`${tab.label.toLocaleUpperCase()}_TAB`}
                      label={tab.label}
                      component={Link}
                      to={tab.href === 'about' ? './' : tab.href}
                      key={tab.href}
                    />
                  ))}
                </Tabs>
              </Box>
              <Outlet />
            </Box>
          </Box>
        </Box>
      ) : (
        <Typography>{`No patient found with ID ${patientId}`}</Typography>
      )}
    </BasePage>
  );
};

export default Patient;
