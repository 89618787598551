import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useDialog, useSnackbar, useLoadingBar } from '@fdha/web-ui-library';
import { parseBackendError } from '@fdha/common-utils';
import {
  RegistrationStatus,
  useResendOneTimePasswordMutation,
  UserType,
} from '@fdha/graphql-api-admin';

import ManageAccountButton from '../ManageAccountButton/ManageAccountButton';

interface ManagePasswordProps {
  email: string;
  cognitoUser: CognitoUser;
  registrationStatus: string;
  type: UserType;
}

const getUserTypeLabel = (userType: UserType) => {
  switch (userType) {
    case UserType.Patient:
      return 'patient';
    case UserType.SiteStaff:
      return 'site staff';
    case UserType.Foodops:
      return 'food ops user';
    default:
      return 'user';
  }
};

const ManagePassword: React.FC<ManagePasswordProps> = ({
  email,
  cognitoUser,
  registrationStatus,
  type,
}) => {
  const theme = useTheme();
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useLoadingBar();

  const [resendOneTimePassword] = useResendOneTimePasswordMutation({
    variables: { email, type },
  });

  const userTypeLabel = getUserTypeLabel(type);

  const handleResendTempPassword = () => {
    openDialog({
      title: 'Send a new one-time password?',
      content: (
        <Typography>
          By sending a new one-time password, the {userTypeLabel} will be sent
          an email containing a new password to be used for their initial login
          to the app. The new one-time password will expire 7 days after being
          issued. All previously issued one-time passwords to log into their
          account will be invalidated.
          <br /> <br />
          Do you want to continue?
        </Typography>
      ),
      confirmButtonLabel: 'Yes, send a new one-time password',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => {
        try {
          closeDialog();
          showLoading();
          await resendOneTimePassword();
          showSnackbar({
            message: `A new one-time password has been sent to the ${userTypeLabel}’s email.`,
            severity: 'success',
          });
        } catch (error) {
          const message = parseBackendError(
            error,
            'Error sending one-time password'
          );

          showSnackbar({
            message,
            severity: 'error',
          });
        } finally {
          hideLoading();
        }
      },
    });
  };

  const handleResetPassword = () => {
    openDialog({
      title: `Reset ${userTypeLabel}’s password?`,
      content: (
        <Typography>
          By resetting the {userTypeLabel}'s password, the {userTypeLabel} will
          be sent an email containing a validation code and a link to create a
          new password. The code will expire 1 hour after being issued.
          <br /> <br />
          Do you want to continue?
        </Typography>
      ),
      confirmButtonLabel: 'Yes, send a validation code',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => {
        closeDialog();
        showLoading();
        cognitoUser.forgotPassword({
          onSuccess: () => {
            hideLoading();
            showSnackbar({
              message: `A validation code has been sent to the ${userTypeLabel}’s email.`,
              severity: 'success',
            });
          },
          onFailure: (err) => {
            console.error(err.message);
            hideLoading();
            showSnackbar({
              message: 'Error to reset password',
              severity: 'error',
            });
          },
        });
      },
    });
  };

  const getManagePasswordItems = () => {
    switch (registrationStatus) {
      case RegistrationStatus.ForceChangePassword:
        return {
          buttonText: 'Send new one-time password',
          buttonTestId: 'RESEND_PASSWORD_BUTTON',
          handler: handleResendTempPassword,
        };
      default:
        return {
          buttonText: 'Reset password',
          buttonTestId: 'RESET_PASSWORD_BUTTON',
          handler: handleResetPassword,
        };
    }
  };

  const managePasswordItems = getManagePasswordItems();

  return (
    <ManageAccountButton
      onClick={managePasswordItems.handler}
      data-testid={managePasswordItems.buttonTestId}
    >
      <Typography variant="h6" color={theme.palette.secondary.contrastText}>
        {managePasswordItems.buttonText}
      </Typography>
    </ManageAccountButton>
  );
};

export default ManagePassword;
